



















































































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import { Debounce } from 'lodash-decorators'
import { debounce } from 'lodash'
import RowActionModel from '@/components/list/RowActionModel'
import RowAction from '@/components/list/RowAction.vue'
import DateField from '@/components/inputs/DateField.vue'
import axios from 'axios'

@Component({
  components: { RowAction, DateField },
})
export default class ListView extends Vue {
  $refs: Vue['$refs'] & {
    filtersForm: {
      validate: any
    }
  }

  // =======================================================================================
  // Props
  // =======================================================================================

  @Prop({ type: Array, required: true })
  private headers: any // Headers to v-data-table

  @Prop({ type: String, required: true })
  private url: string // API URL to fetch rows

  @Prop({ type: Object, required: false })
  private query: Record<string, unknown> // Query-params used in ^url that should also be in browser-url

  @Prop({ type: String, required: false })
  private title: string

  @Prop({ type: Object, required: false })
  private texts: any

  @Prop({ type: Array, required: false, default: null })
  private topActions: Array<any>

  @Prop({ type: Array, required: false, default: null })
  private selectActions: Array<any>

  @Prop({ type: Array, required: false, default: null })
  private rowActions: Array<any>

  @Prop({ type: String, required: false, default: '' })
  private defaultSearch: string

  @Prop({ type: String, required: false, default: '' })
  private sortBy: string

  @Prop({ type: Boolean, required: false, default: false })
  private sortDesc: boolean

  @Prop({ type: Number, required: false, default: 50 })
  private itemsPerPage: number

  @Prop({ type: Boolean, required: false, default: false })
  private showSelect: any

  @Prop({ required: false })
  private itemClass: any

  @Prop({ type: String, required: false, default: '' })
  private tableClass: any

  @Prop({ required: false, default: () => ({ 'items-per-page-options': [10, 30, 50, 100, 250] }) })
  private footerProps: any

  @Prop({ type: Boolean, required: false, default: false })
  private hideDefaultFooter: any

  @Prop({ required: false })
  private itemProcessor: any

  @Prop({ type: Boolean, required: false, default: true })
  private enableSearch: boolean

  @Prop({ type: Boolean, required: false, default: true })
  private enableFilters: boolean

  @Prop({ type: Array, required: false, default: () => [] })
  private additionalFilters: Array<any>

  @Prop({ type: String, required: false, default: null })
  private groupBy: any

  @Prop({ required: false, default: true })
  private dense: boolean

  // =======================================================================================
  // Variables
  // =======================================================================================

  private loading = true
  private initialized = false
  private text = {}
  private items = []
  private search = ''
  showFiltersDialog = false
  count = 0
  searchWasCtrlEnter = false
  preventAutocompleteName = ''
  options = {
    sortDesc: [],
    sortBy: [],
    page: 1,
    itemsPerPage: 100,
  }

  filters = {}
  filtersPrefixes = {}
  filtersDisabled = {}
  updateFiltersError = false
  hotkeyEventListener = null

  private selectedMenu = false
  private selectedRows = []
  private allPagesRowsSelected = false

  private emptyFilterKey = 'EMPTY'
  private notEmptyFilterKey = 'NOTEMPTY'
  private pastFilterKey = '(PAST)'
  private todayFilterKey = '(TODAY)'
  private futureFilterKey = '(FUTURE)'
  private betweenFilterKey = '(BETWEEN)'

  private confirmationDialog = false
  private confirmationDialogLoading = false
  private confirmationDialogAction = null
  private confirmationDialogItem = null

  private axiosSource = null
  private prevUrl = null

  // We declare in this way and not like navigateAndLoad because when it is called withing navigateAndLoad we want to call it without debouncing
  //   since navigateAndLoad is already debounced, but when we call it directly we want it to be debounced
  private debouncedLoad = debounce(() => {
    this.load()
  }, 100)

  // =======================================================================================
  // Initialization
  // =======================================================================================

  mounted() {
    // Seems the only robust way to avoid autocomplete suggestions from browser is to set a random input name, like: <input name="randomstring">

    this.axiosSource = axios.CancelToken.source()

    this.preventAutocompleteName = new Date().getTime() + '-' + Math.random()

    // Define filters

    this.filterInputs.forEach((filter) => {
      Vue.set(this.filters, filter.key, null)
      Vue.set(this.filtersPrefixes, filter.key, '')
      Vue.set(this.filtersDisabled, filter.key, false)
    })

    // Texts

    const defaultTexts = {
      searchInputLabel: 'Search',
      searchButtonTooltip: 'Search',
      resetButtonTooltip: 'Reset',
      filtersTitle: 'Filters',
      filtersOpenButtonTooltip: 'Filters',
      filtersResetButtonLabel: 'Reset',
      filtersUpdateButtonLabel: 'Update',
      filtersCloseButtonLabel: 'Close',
      filtersExampleHint: 'Examples: foo@bar.com, foo@*.co*, foo@',
    }
    for (const k in defaultTexts) {
      const defaultText = defaultTexts[k]
      const propText = this.texts ? this.texts[k] : null
      this.text[k] = propText && propText !== '' ? propText : defaultText
    }

    // Hotkeys

    this.hotkeyEventListener = (event) => {
      if (event.altKey) {
        if (event.key === 's') {
          event.preventDefault()
          const ref = this.$refs.searchInput as Vue
          if (ref?.$el) {
            const input = ref.$el.querySelector('input[type=text]') as HTMLInputElement
            if (input) {
              input.focus()
            }
          }
        }
        if (event.key === 'r') {
          event.preventDefault()
          this.resetSearch()
        }
        if (event.key === 'f') {
          event.preventDefault()
          this.showFiltersDialog = true
        }
        if (event.key === 'n' && this.newAction && this.newAction.route) {
          event.preventDefault()
          const route = this.newAction.route()
          if (route) {
            this.$router.push(route)
          }
        }
      }
    }
    window.addEventListener('keydown', this.hotkeyEventListener)

    // Route to params
    this.routeToParams()

    // Load data
    this.debouncedLoad()
  }

  destroyed() {
    window.removeEventListener('keydown', this.hotkeyEventListener)
    this.axiosSource.cancel()
    this.debouncedLoad.cancel()
  }

  private getQueryParam(key): string {
    if (this.$route.query[key] !== undefined) {
      const value = this.$route.query[key]
      if (value instanceof Array) {
        return value[0] ? '' + value[0] : ''
      } else {
        return value ? '' + value : ''
      }
    } else {
      return undefined
    }
  }

  // =======================================================================================
  // Reload data if props relevant to url/query changes from parent
  // =======================================================================================

  @Watch('query')
  onQueryChange() {
    this.navigateAndLoad()
  }

  @Watch('url')
  onUrlChange() {
    this.navigateAndLoad()
  }

  @Watch('defaultSearch')
  onDefaultSearchChange() {
    this.debouncedLoad()
  }

  @Watch('$route', { deep: true })
  private routeToParams() {
    const searchParam = this.getQueryParam('search')
    const pageParam = this.getQueryParam('page')
    const perPageParam = this.getQueryParam('perPage')
    const sortByParam = this.getQueryParam('sortBy')
    const sortDescParam = this.getQueryParam('sortDesc')

    this.search = searchParam === undefined ? '' : searchParam
    this.options.page = pageParam === undefined ? 1 : parseInt(pageParam)
    this.options.itemsPerPage = perPageParam === undefined ? this.itemsPerPage : parseInt(perPageParam)
    this.options.sortBy[0] = sortByParam === undefined ? this.sortBy : sortByParam
    this.options.sortDesc[0] = sortDescParam === undefined ? this.sortDesc : !!sortDescParam
  }

  // =======================================================================================
  // Load data from backend
  // =======================================================================================

  load() {
    this.items = []

    if (!this.url) {
      this.count = 0
      this.loading = false
      return
    }

    let search = this.search
    if (this.defaultSearch) {
      if (search) {
        search += ' ' + this.defaultSearch
      } else {
        search = this.defaultSearch
      }
    }
    this.selectedRows = []

    const { sortBy, sortDesc, page, itemsPerPage } = this.options

    // For URLSearchParams to not complain, I needed to cast all to string
    const params = {
      sortBy: String(sortBy[0] || ''),
      sortDesc: String(sortDesc[0] ? '1' : ''),
      page: String(page),
      perPage: String(itemsPerPage === -1 ? 100000 : itemsPerPage),
      search: String(search || ''),
      searchToFilters: String(1),
    }

    // From props, in case something was overwritten/added at parent class
    const query = this.query || {}
    for (const key in query) {
      if (query[key] !== undefined && query[key] !== null && query[key] !== '') {
        params[key] = query[key]
      }
    }

    const queryString = new URLSearchParams(params).toString()

    const separator = this.url.indexOf('?') === -1 ? '?' : '&'

    const _url = this.url + separator + queryString
    const _canLoadData = !this.prevUrl || _url !== this.prevUrl || !this.loading

    // NOTE: API call when first load or url not the same or not loading
    if (_canLoadData) {
      if (this.loading) {
        this.axiosSource.cancel()
        this.axiosSource = axios.CancelToken.source()
      }

      this.loading = true

      this.$axios
        .get(_url, { cancelToken: this.axiosSource.token })
        .then((response) => {
          if (this.itemProcessor) {
            this.items = this.itemProcessor(response.data.data || [])
          } else {
            this.items = response.data.data || []
          }
          this.count = response.data.meta.totalItemsCount
          if (this.items.length === 1 && this.searchWasCtrlEnter) {
            const route = this.getShowRoute(this.items[0])
            if (route) {
              this.$router.push(route)
            }
          }
          this.searchWasCtrlEnter = false
          this.$emit('data', { count: this.count, items: this.items, responseData: response.data })
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            this.count = 0
            const message = err?.response?.data?.error?.message || 'Unknown error'
            vxm.alert.error({
              content: 'Error fetching list: ' + message,
              title: this.$t('c:common:Error') as string,
            })
          }
        })
        .finally(() => {
          this.loading = false
          // At the first load() we pick settings from route query (see mounted()), but after
          // that we start watching props for change (sorting, searching, etc), so set initialized to signal that
          if (!this.initialized) {
            this.initialized = true
          }
        })
    }
    this.prevUrl = _url
  }

  // =======================================================================================
  // Searching, filtering, sorting - and related route handling
  // =======================================================================================

  // When params to backend change, we update url query, and load new data
  private navigateAndLoad(resetPage = true) {
    if (resetPage) {
      this.options.page = 1
    }
    const { sortBy, sortDesc, page, itemsPerPage } = this.options
    const route = {
      name: this.$route.name,
      params: this.$route.params,
      query: {
        sortBy: sortBy?.[0] || '',
        sortDesc: sortDesc?.[0] ? '1' : '',
        page: '' + page,
        perPage: '' + itemsPerPage,
        search: this.search || '',
      },
    }
    const query = this.query || {}
    for (const key in query) {
      if (query[key] !== undefined && query[key] !== null && query[key] !== '') {
        route.query[key] = query[key]
      }
    }

    if (!this.areRoutesEqual(route, this.$router.currentRoute)) {
      this.$router.push(route)
    } else {
      console.log('Ignore duplicate route:', route)
    }

    this.debouncedLoad.cancel()
    this.debouncedLoad()
  }

  areRoutesEqual(a, b): boolean {
    const toJson = (r): string => {
      const clone = {
        name: r.name,
        params: {},
        query: {},
      }
      if (r.params) {
        for (const key in r.params) {
          clone.params[key] = '' + r.params[key]
        }
      }
      if (r.query) {
        for (const key in r.query) {
          clone.query[key] = '' + r.query[key]
        }
      }
      return JSON.stringify(clone)
    }
    return toJson(a) === toJson(b)
  }

  // Update when sorting and pagination options change
  @Watch('options', { deep: true })
  onOptionsChange(_options) {
    if (!this.initialized) {
      return
    }
    this.navigateAndLoad(false)
  }

  // Update when search is executed (by clicking button or pressing enter)
  executeSearch(searchWasCtrlEnter = false) {
    if (searchWasCtrlEnter) {
      this.searchWasCtrlEnter = true
    }
    this.navigateAndLoad()
  }

  // Update when pressing enter in search input
  checkSearchEnter(e) {
    // If user searches and then hits Ctrl+Enter, we try to navigate to the item page of the search result, if there is exactly one search result,
    // so we must keep track of whether Ctrl was pressed, so we have this for later when the backend call completes.
    if (e.key === 'Enter') {
      this.executeSearch(e.ctrlKey)
    }
  }

  // Update when wiping search with reset button
  resetSearch() {
    this.search = ''
    this.resetFilters()
    this.navigateAndLoad()

    this.$emit('search', '')
  }

  // =======================================================================================
  // Filters
  // =======================================================================================

  // After submitting filter dialog, update search query and execute search
  private updateFilters() {
    if (this.$refs.filtersForm.validate()) {
      this.updateFiltersError = false
      let search = this.search || ''
      let value: string
      for (const key in this.filters) {
        if (!this.filters[key]) {
          value = ''
        } else {
          value = this.filters[key].toString().replace('"', '').trim()
        }
        const pattern1 = new RegExp(key + ':(?:=|!=)?"[^"]+"')
        const pattern2 = new RegExp(key + ':[^ ]*')
        search = search.replace(pattern1, '')
        search = search.replace(pattern2, '')

        if (this.filtersPrefixes[key] === this.emptyFilterKey) {
          search += ' ' + key + ':'
        } else if (this.filtersPrefixes[key] === this.notEmptyFilterKey) {
          search += ' ' + key + ':*'
        } else if (value) {
          if (value.indexOf(' ') !== -1) {
            value = '"' + value.replace('"', '') + '"'
          }
          search += ' ' + key + ':' + (this.filtersPrefixes[key] || '') + value
        } else if (
          this.filtersPrefixes[key] === this.pastFilterKey ||
          this.filtersPrefixes[key] === this.todayFilterKey ||
          this.filtersPrefixes[key] === this.futureFilterKey
        ) {
          search += ' ' + key + ':' + this.filtersPrefixes[key]
        }
      }
      this.search = search.trim()
      this.showFiltersDialog = false
      this.executeSearch()
    } else {
      this.updateFiltersError = true
    }

    this.$emit('search', this.search)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  public updateOneFilter(key: string, prefix: string, value: any): void {
    Vue.set(this.filters, key, value)
    Vue.set(this.filtersPrefixes, key, prefix)

    this.updateFilters()
  }

  // Allow enter in any filter input to mean submit
  checkFilterInputEnterKey(e) {
    if (e.key === 'Enter') {
      this.updateFilters()
    }
  }

  // Clear value of all filter inputs
  resetFilters() {
    for (const key in this.filters) {
      this.filters[key] = null
      this.filtersPrefixes[key] = null
    }
  }

  // When filter dialog opens, focus a selected filter input if that's configured
  @Watch('showFiltersDialog')
  onShowFiltersDialog(value) {
    if (!value) {
      return
    }
    setTimeout(() => {
      // We need setTimeout because it takes a little while from showFiltersDialog attr changes until the dialog is actually rendered
      if (this.$refs.filtersContainer) {
        const elem = (this.$refs.filtersContainer as Element).querySelector(
          '.focused input[type=text]',
        ) as HTMLInputElement
        if (elem) {
          elem.focus()
        }
      }
    }, 200)
  }

  // Automatically obtain list of filter inputs from :headers and defaults (only used unless <slot name="filters"> is passed though)
  get filterInputs() {
    const filters = []
    let hasFocus = false
    for (let i = 0; i < this.headers.length; i++) {
      const filter = this.headers[i].filter || {}
      if (!filter.key) {
        filter.key = this.headers[i].value
      }
      if (filter.disable === undefined) {
        filter.disable = this.headers[i].value === 'actions'
      }
      if (!filter.label) {
        filter.label = this.headers[i].text
      }
      if (filter.focus !== undefined) {
        // We use undefined because we default to set focus on first filter, but want to allow disabling this by defining filter.focus=false on the first input
        filter.focus = !!filter.focus
        hasFocus = true
      } else {
        filter.focus = false
      }
      if (filter.items) {
        if (filter.multiple === undefined || filter.multiple === null) {
          filter.multiple = true
        }
        if (!filter.itemText) {
          filter.itemText = 'text'
        }
        if (!filter.itemValue) {
          filter.itemValue = 'value'
        }
      }
      if (!filter.disable) {
        filters.push(filter)
      }
    }
    if (!hasFocus && filters.length > 0) {
      filters[0].focus = true
    }

    this.additionalFilters.forEach((filter) => {
      filters.push(filter)
    })

    return filters
  }

  // =======================================================================================
  // Delete row(s)
  // =======================================================================================

  // Confirm deletion - actually delete in backend and reload list
  private async deleteConfirm(forceItem = null, deleteUrl = null) {
    if (!forceItem) {
      const data = {
        ids: this.selectedRows.map(function(a) {
          return a.id
        }),
        allPagesRowsSelected: this.allPagesRowsSelected,
      }
      await this.$axios
        .post(this.url + '/many/delete', data)
        .then(() => {
          this.selectedRows = null
          this.load()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    } else {
      await this.$axios
        .delete((deleteUrl || this.url) + '/' + forceItem.id)
        .then(() => {
          this.load()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  // =======================================================================================
  // For v-data-table, pass thru item slots, and use some defaults for header details
  // =======================================================================================

  get dataTableSlots() {
    const result = {}
    for (const key in this.$scopedSlots) {
      if (key.substr(0, 5) === 'item.') {
        result[key] = this.$scopedSlots[key]
      }
    }
    return result
  }

  get headersWithDefaults() {
    const result = []
    const currentHeaders = JSON.parse(JSON.stringify(this.headers)) // todo: probably a better way to clone headers
    for (let i = 0; i < currentHeaders.length; i++) {
      const h2 = currentHeaders[i]
      if (h2.hidden) continue
      if (h2.value === 'actions' && h2.sortable === undefined) {
        h2.sortable = false
      }
      // If we do not have a divider defined, and we are not in the last cell, then add a divider
      if (h2.divider === undefined && i !== currentHeaders.length - 1) {
        h2.divider = true
      }
      /* tried to make it set TD.class but cannot get it to work. headers.class applies to TH, and template items is overridden by template item.xxx ?
      if (!h2.class) {
        h2.class = h2.text
          .toLowerCase()
          .replace(' ', '-')
          .replace(/^c:[^:]+:/, '')
          .replace(/[^a-z0-9\-]/, '')
      }
      h2.key = '' + i
      */
      h2.text = this.$t(h2.text)
      result.push(h2)
    }
    return result
  }

  // =======================================================================================
  // Actions
  // =======================================================================================

  get newAction(): EonButtonModel {
    if (!this.topActions) {
      return null
    }
    for (let i = 0; i < this.topActions.length; i++) {
      if (this.topActions[i].id === 'new') {
        return this.topActions[i]
      }
    }
    return null
  }

  get topActionsWithDefaults() {
    const result = []
    if (this.topActions) {
      for (let i = 0; i < this.topActions.length; i++) {
        const action = this.decorateTopAction(i, this.topActions[i])
        if (action) {
          result.push(action)
        }
      }
    }
    return result
  }

  private get selectActionsWithDefaults() {
    const result = []
    if (this.selectActions) {
      for (let i = 0; i < this.selectActions.length; i++) {
        const action = this.decorateTopAction(i, this.selectActions[i])
        if (action) {
          if (!this.selectedRows.length) {
            action.disabled = true
          } else {
            action.disabled = false
          }
          result.push(action)
        }
      }
    }
    return result
  }

  decorateTopAction(index, data) {
    const action: EonButtonModel = data
    if (!action.id) {
      console.error('Top action #' + index + ' is missing "id", will generate one')
      action.id = 'top-' + index
    }
    if (!action.route && !action.click) {
      if (action.id === 'delete') {
        if (action.requiresConfirmation === undefined) {
          action.requiresConfirmation = true
        }
        if (action.requiresConfirmation && !action.confirmationText) {
          action.confirmationText = 'c:list-view:Are you sure you want to delete the selected rows?'
        }
        if (action.color === undefined) {
          action.color = 'red'
          action.class += ' white--text'
        }
        action.click = async() => {
          await this.deleteConfirm()
        }
      } else {
        console.error(
          'Top action #' + index + ' is missing both "route" and "click", nothing will happen when you press it',
        )
      }
    }
    if (!action.label) {
      action.label = action.id.substr(0, 1).toUpperCase() + action.id.substr(1).toLowerCase()
    }
    if (action.id === 'new') {
      action.shortcut = 'Alt+N' // todo: cannot actually pass in/control shortcut per now, this is just the text displayed in tooltip, see mounted()
      if (!action.tooltip && data.tooltip === null) {
        action.tooltip = 'Create new item'
      }
    }
    return action
  }

  get rowActionsWithDefaults() {
    const result = []
    if (this.rowActions) {
      for (let i = 0; i < this.rowActions.length; i++) {
        const action = this.decorateRowAction(i, this.rowActions[i])
        if (action) {
          result.push(action)
        }
      }
    }
    return result
  }

  decorateRowAction(index, data) {
    const action = new RowActionModel(data)
    if (!action.id) {
      console.error('Row action #' + index + ' is missing "id", will generate one')
      action.id = 'row-action-' + index
    }
    if (!action.route && !action.click) {
      if (action.id === 'delete') {
        if (action.requiresConfirmation === null) {
          action.requiresConfirmation = true
        }
        if (action.requiresConfirmation && !action.confirmationText) {
          action.confirmationText = 'c:list-view:Are you sure you want to delete this row?'
        }
        action.click = async(parent, item) => {
          await this.deleteConfirm(item, data.deleteUrl)
        }
      } else {
        console.error(
          'Row action #' + index + ' is missing both "route" and "click", nothing will happen when you press it',
        )
      }
    }
    if (!action.label) {
      action.label = action.id.substr(0, 1).toUpperCase() + action.id.substr(1).toLowerCase()
    }
    return action
  }

  getShowRoute(item) {
    const actions = this.rowActionsWithDefaults
    for (let i = 0; i < actions.length; i++) {
      if (actions[i].id === 'view') {
        return actions[i].route(item)
      }
    }
    return null
  }

  private onFiltersPrefixesChanged(key) {
    if (this.filtersPrefixes[key] === this.emptyFilterKey || this.filtersPrefixes[key] === this.notEmptyFilterKey) {
      Vue.set(this.filters, key, '')
      Vue.set(this.filtersDisabled, key, true)
    } else {
      Vue.set(this.filtersDisabled, key, false)
    }
  }

  private selectAllRows() {
    this.selectedRows = this.items
  }

  private invertSelectedRows() {
    const newSelectedRows = []
    for (let i = 0; i < this.items.length; i++) {
      if (this.selectedRows.indexOf(this.items[i]) === -1) {
        newSelectedRows.push(this.items[i])
      }
    }
    this.selectedRows = newSelectedRows
  }

  private unselectAllRows() {
    this.selectedRows = []
    this.allPagesRowsSelected = false
  }

  private selectAllCheckbox() {
    if (this.selectedRows.length === 0) {
      this.selectedRows = this.items
    } else {
      this.selectedRows = []
    }
  }

  private selectAllPagesRows() {
    this.allPagesRowsSelected = true
  }

  private appScroll() {
    this.debounceScroll()
  }

  @Debounce(250)
  private debounceScroll() {
    if (this.selectedMenu) {
      setTimeout(() => (this.selectedMenu = false), 0)
      setTimeout(() => (this.selectedMenu = true), 0)
    }
  }

  @Watch('headers')
  private onHeadersChanged() {
    this.filterInputs.forEach((filter) => {
      Vue.set(this.filters, filter.key, null)
      Vue.set(this.filtersPrefixes, filter.key, '')
      Vue.set(this.filtersDisabled, filter.key, false)
    })
  }

  // @Debounce(1000)
  // private selectedMenuLeave() {
  //   if (!this.selectedRows.length) {
  //     this.selectedMenu = false
  //   }
  // }

  @Watch('selectedRows')
  private onSelectedRowsChanged() {
    if (!this.selectedRows.length) {
      setTimeout(() => {
        this.selectedMenu = false
      }, 0)
    } else if (this.selectedRows.length > 0) {
      setTimeout(() => {
        this.selectedMenu = true
      }, 0)
    }
    this.$emit('selectedRows', { selectedRows: this.selectedRows })
  }

  private async actionClicked() {
    this.confirmationDialogLoading = true
    await this.confirmationDialogAction.click(this, this.confirmationDialogItem)
    this.confirmationDialogLoading = false
    this.confirmationDialog = false
  }

  private onConfirmationDialog(data) {
    this.confirmationDialogAction = data.action
    this.confirmationDialogItem = data.item
    this.confirmationDialog = true
  }

  private hasScopedSlot(slotName) {
    const scopedSlot = this.$scopedSlots[slotName]
    if (scopedSlot === undefined || scopedSlot === null) {
      return false
    }
    const scopedSlotResolved = scopedSlot(null)
    return !!scopedSlotResolved?.[0]
  }
}
